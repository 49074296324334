import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import Loader from '../../../Loader/Loader';

import styles from './ContactForm.module.scss';

const recaptchaRef = React.createRef();

const ContactForm = props => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [formResponse, setFormResponse] = useState('');
  const [isDisabled, setIsDisabled] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    // event.preventDefault();

    setIsDisabled('disabled');
    setIsLoading(true);

    const baseURL = 'https://mailer.monolit.hr/mailer.php';
    const params = `?name=${name}&email=${email}&subject=${subject}&message=${message}`;
    const endpoint = baseURL + '/' + encodeURI(params);

    axios({
      method: 'post',
      url: endpoint,
      // data: {
      //   name: name,
      //   email: email,
      //   subject: subject,
      //   message: message,
      // },
    })
      .then(response => {
        if (200 === response.status) {
          let message = response.data.message;

          if ('success' === response.data.status) {
            message = 'Hvala vam na poruci. Poslana je.';
          } else if ('error' === response.data.status) {
            message =
              'Došlo je do greške kod pokušaja slanja vaše poruke. Pokušajte ponovno kasnije.';
          }

          setFormResponse(message);
          setName('');
          setEmail('');
          setSubject('');
          setMessage('');
        }
        setIsLoading(false);
        //console.log('R: ', response);
      })
      .catch(error => {
        console.log('E: ', error);
        setFormResponse(
          'Došlo je do greške kod pokušaja slanja vaše poruke. Pokušajte ponovno kasnije.'
        );
        setIsLoading(false);
      });
  };

  let formClasses = [styles.Form];

  if (isDisabled) {
    formClasses.push(styles.Disabled);
    formClasses.push('disabled');
  }

  if (formResponse) {
    formClasses.push(styles.ShowMessage);
  }

  return (
    <form
      method="post"
      className={formClasses.join(' ')}
      onSubmit={e => {
        e.preventDefault();
        recaptchaRef.current.execute();
      }}
    >
      <label htmlFor="name">Ime</label>
      <input
        type="text"
        name="name"
        id="name"
        placeholder="Ime"
        aria-label="Ime"
        required
        value={name}
        onChange={e => setName(e.target.value)}
        disabled={isDisabled}
      />
      <label htmlFor="email">E-mail</label>
      <input
        type="email"
        name="email"
        id="email"
        placeholder="E-mail"
        aria-label="E-mail"
        required
        value={email}
        onChange={e => setEmail(e.target.value)}
        disabled={isDisabled}
      />
      <label htmlFor="subject">Predmet</label>
      <input
        type="text"
        name="subject"
        id="subject"
        placeholder="Predmet"
        aria-label="Predmet"
        value={subject}
        onChange={e => setSubject(e.target.value)}
        disabled={isDisabled}
      />
      <label htmlFor="message">Poruka</label>
      <textarea
        name="message"
        id="message"
        rows="10"
        placeholder="Poruka"
        aria-label="Poruka"
        required
        value={message}
        onChange={e => setMessage(e.target.value)}
        disabled={isDisabled}
      ></textarea>

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LdT7-MUAAAAAERmmxng_CAbiD9M-5UQ7cm9OPSQ" // invisible
        onChange={handleSubmit}
      />

      <button
        type="submit"
        className="btn btn-primary"
        disabled={isDisabled}
        aria-label="Submit contact form"
      >
        Pošalji
      </button>

      <div className={styles.FormResponse}>
        <p className={styles.Message}>{formResponse}</p>
      </div>

      <Loader loading={isLoading} />
    </form>
  );
};

export default ContactForm;
