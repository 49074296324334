import React from 'react';

import styles from './Loader.module.scss';

const Loader = props => {
  let classes = styles.Roller;

  if (props.loading) {
    classes = [styles.Roller, styles.Show].join(' ');
  }

  return (
    <div className={classes}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loader;
