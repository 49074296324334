import React, { useState, useEffect } from 'react';

import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = ({ current }) => {
  const [homeURL, setHomeURL] = useState('/');
  const [currentURL, setCurrentURL] = useState('');

  useEffect(() => {
    // componentDidMount
    setHomeURL(window.location.origin);
    setCurrentURL(window.location.href);
  }, []);

  return (
    <div className={styles.Breadcrumbs} id="breadcrumbs">
      <ol itemScope itemType="http://schema.org/BreadcrumbList">
        <li
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          {' '}
          <a itemProp="item" href={homeURL}>
            <span itemProp="name">Naslovnica</span>
          </a>
          <meta itemProp="position" content="1" />
        </li>
        <li
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          {/* {' '}
          <a itemProp="item" href="#">
            <span itemProp="name">Parent</span>
          </a>
          <meta itemProp="position" content="1" />
        </li>
        <li
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        > */}{' '}
          <a itemProp="item" href={currentURL} className={styles.Current}>
            <span itemProp="name">{current}</span>
          </a>
          <meta itemProp="position" content="2" />
        </li>
      </ol>
    </div>
  );
};

export default Breadcrumbs;
