import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import styles from './Hero.module.scss';

const Hero = ({ image, text, breadcrumbs }) => {
  const title = text ? <h1>{text}</h1> : null;

  breadcrumbs = breadcrumbs ? <Breadcrumbs current={text} /> : null;

  return (
    <BackgroundImage Tag="section" className={styles.Hero} fluid={image}>
      {title}
      {breadcrumbs}
    </BackgroundImage>
  );
};

export default Hero;
