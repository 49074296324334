import React from 'react';
import styles from './Contact.module.scss';
import ContactInfo from './ContactInfo/ContactInfo';
import ContactForm from '../UI/Forms/ContactForm/ContactForm';

const Contact = ({ title }) => {
  return (
    <section id="contact" className={styles.Contact}>
      <div className={`container padding-1 ${styles.Container}`}>
        <h2 className="section-title">{title}</h2>
        <ContactForm />
        <ContactInfo type="horizontal" />
      </div>
    </section>
  );
};

export default Contact;
